import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import {Breadcrumb,Container,Row,Col} from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router"
import "./Breadcrumbs.scss"

import { ABOUT_PAGE_URL, AREA_GUIDE_PAGE_URL } from "../../common/site/constants"


// markup
const AreaGuideBreadcrumbs = (props ) => {
  const [render, setRender] = useState(false);
  const { href } = useLocation();
  let siteUrl= process.env.GATSBY_SITE_URL;
  var ldJson = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
  };
  var itemListElement = [
    { "@type": "ListItem", "position": 1, "name": "Home", "item":  `${siteUrl}` },
    { "@type": "ListItem", "position": 2, "name": ABOUT_PAGE_URL.name, "item":  `${siteUrl}${ABOUT_PAGE_URL.alias}` },
    { "@type": "ListItem", "position": 3, "name": AREA_GUIDE_PAGE_URL.name, "item":  `${siteUrl}${AREA_GUIDE_PAGE_URL.alias}` },
  ]; 
  if(props.Pagename){
    itemListElement.push({ "@type": "ListItem", "position": 4, "name": props.Pagename, "item":  href }) 
  }
  ldJson['itemListElement'] =  itemListElement; 
  useEffect(()=>{
    if(!render){
      setRender(true);
    }
  },[])
  return ( 
        <React.Fragment>
            <Helmet>
            {render &&
            <script type="application/ld+json">
            {JSON.stringify(ldJson, null, 2)}
          </script>
          } 
          </Helmet>
           <div className="breadcrumb-wrap">
           <Container>
             <Row>
               <Col md='12'>
                  <Breadcrumb>
                    <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
                      <Breadcrumb.Item><Link to={`${ABOUT_PAGE_URL.alias}`}>{ABOUT_PAGE_URL.name}</Link></Breadcrumb.Item>
                      <Breadcrumb.Item><Link to={`${AREA_GUIDE_PAGE_URL.alias}`}>{AREA_GUIDE_PAGE_URL.name}</Link></Breadcrumb.Item>
                    {/* <Breadcrumb.Item href="#">Areas we cover</Breadcrumb.Item> */}
                    <Breadcrumb.Item active>{props.Pagename}</Breadcrumb.Item>
                  </Breadcrumb>
               </Col>
             </Row>
           </Container>
           </div>
        </React.Fragment>
    )
}
export default AreaGuideBreadcrumbs